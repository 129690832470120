import { RatesBlockCryptoCarousel } from './rates-crypto-currency-list';
import { HowToBlockSlider } from "../blocks/how-to-blocks-slider";
import { GlobalStorage } from "./global-storage";
import eventBus from "../helpers/event-bus";
import exchangeFormEventNames from "../exchange-form/exchange-form-event-names";
import { updateContent } from "../blocks/seo-content-blocks";

document.addEventListener('DOMContentLoaded', () => {
  GlobalStorage.init();
  RatesBlockCryptoCarousel.init();
  HowToBlockSlider.init();
});

eventBus.on(exchangeFormEventNames.FORM_CHANGED_SERVICES, ({fiatCurrencyCode, relationLink}) => {
  if (!GlobalStorage.isInitiated) {
    // If global store not initiated, this means we are just loading page and all the content should already be pre-rendered
    return;
  }
  updateContent(relationLink, fiatCurrencyCode);
});
