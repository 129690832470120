import PayoutService from "../services/payout";
import { getCurrentLocale } from "../helpers/helper";
import LazyBlockService from "../services/lazy-block-service";

const toggleElement = (element, toggleRule) => {
  if (element === null) {
    return;
  }

  element.style.visibility = toggleRule ? 'visible' : 'hidden';
};

const updateBlock = (block, newContent) => {
  if (block === null) {
    return;
  }

  toggleElement(block, newContent.length > 0);
  const container = block.querySelector(".container");

  if (container === null) {
    return;
  }

  container.innerHTML = newContent;
};

document.body.addEventListener("page-content-changed", function (event) {
  const { withPaybisYouCanAlso, metaTitle } = event.detail;

  const youCanAlsoBlock = document.getElementById("with-paybis-you-can-also");
  if (youCanAlsoBlock) {
    youCanAlsoBlock.innerHTML = withPaybisYouCanAlso;
  }

  document.title = metaTitle
});

export const updateContent = (relationLink, currency) => {
  const container = document.querySelector("#how-does-it-work");
  if (!container) {
    return;
  }
  const loader = container.querySelector(".loading-description");
  if (!loader) {
    return;
  }
  toggleElement(loader, true);

  const { currency: currentCurrency, relationLink: currentLink } = container.dataset;

  if (currentCurrency === currency && currentLink === relationLink) {
    return;
  }

  container.dataset.currency = currency;
  container.dataset.relationLink = relationLink;

  // Blocks where cotent is different for buy/sell flow
  const blocksForUpdate= ['ctaBlock', 'ctaFull'];
  for(const block of blocksForUpdate) {
    const container = document.querySelector(`[data-block="${block}"]`);
    if (container && !container.classList.contains('lazy')) {
      LazyBlockService.getBlockHtml(block).then((html) => {
        container.innerHTML = html;
      });
    }
  }

  const language = getCurrentLocale();
  return PayoutService.getDescription({ link: relationLink, language, currency })
    .then((data) => {
      const pageContentChangeEvent = new CustomEvent("page-content-changed", {
        detail: data,
      });

      const {
        howItWorks,
        quickGuide,
        faq,
        features,
        fcaNotificationFooter,
      } = data;

      toggleElement(loader, false);

      const description = document.querySelector("#how-it-works-block");
      if (description) {
        description.innerHTML = howItWorks;
      }

      const steps = document.querySelector("#quick-guide");
      if (steps) {
        updateBlock(steps, quickGuide);
      }
      const faqBlock = document.querySelector("#popular-faq");
      if (faqBlock) {
        updateBlock(faqBlock, faq);
      }
      const featuresBlock = document.querySelector("#our-features");
      if (featuresBlock) {
        updateBlock(featuresBlock, features);
      }

      const fcaNotificationFooterBlock = document.querySelector("#fca-notification-footer");
      if (fcaNotificationFooterBlock) {
        fcaNotificationFooterBlock.innerHTML = fcaNotificationFooter;
      }

      toggleElement(container, howItWorks.trim().length > 0);

      document.body.dispatchEvent(pageContentChangeEvent);
  });
};
